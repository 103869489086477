<template>
  <div class="taskDetails" v-if="show">
    <van-form @submit="onSubmit" ref="formData">
      <van-nav-bar title="任务详情" left-arrow @click-left="onClickLeft"> </van-nav-bar>
      <div
        :style="{
          overflow: 'auto',
          height: 'calc(100vh - 44px - 65px - 10px)',
          'padding-bottom': '65px',
          'padding-top': '10px'
        }"
      >
        <div v-for="(item, index) in dataList" :key="item.sort">
          <!-- {{ item }} -->
          <div class="cardBox">
            <div class="flexBox" style="margin-bottom: 15px">
              <div class="indexBox">{{ index + 1 }}、</div>
              <div class="taskTittle" style="width: 100%">
                {{ item.title }}
                <div style="font-size: 13px; color: #999; margin-top: 2px">
                  <div style="display: flex; justify-content: space-between">
                    <div style="color: #ff4d4f; font-size: 14px">
                      <!-- 如果旧数据图片类型没有sampleText的话  显示'请上传1张照片' -->
                      <span>{{ item.sampleText || (item.itemType === 3 ? '请上传1张照片' : '') }}</span>
                    </div>
                    <div class="example" v-if="item.sampleImg">
                      <span @click="imgPre(item.sampleImg)">查看示例</span>
                      <img src="@/assets/icon/1-右.svg" alt="" class="arrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.itemType == 0 || item.itemType == 5">
              <!-- code是每道题的code -->
              <TextField :item="item" :code="item.code" />
            </div>
            <div v-else-if="item.itemType == 1">
              <RadioField :item="item" :code="item.code" :index="index" @changeRadio="changeRadioBox" />
            </div>
            <div v-else-if="item.itemType == 2">
              <CheckField :item.sync="dataList[index]" :itemOptionCode="item.itemOptionCode" :code="item.code" :index="index" @changeCheck="changeCheckBox" />
            </div>
            <!-- 20230206 移除itemImg字段全部使用 itemImgs -->
            <!-- <div v-else-if="item.itemType == 3 && item.itemTypeF !== 999">
              <UploadField :item.sync="dataList[index]" :code="item.code" />
            </div> -->
            <!-- 20230206 移除itemImg字段全部使用 itemImgs -->
            <!-- <div v-else-if="item.itemTypeF === 999"> -->
            <div v-else-if="item.itemType === 3">
              <UploadFields :item.sync="dataList[index]" :code="item.code" :nofatch="true" />
            </div>
          </div>
        </div>
      </div>
      <div class="btnBox">
        <van-button class="cancel" type="default" native-type="button" @click="onClickLeft"> 取消 </van-button>
        <van-button
          class="submit"
          type="primary"
          :disabled="computedDisabled()"
          :class="computedDisabled() ? 'disabledSub' : ''"
          native-type="submit"
        >
          确定
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast, ImagePreview } from 'vant'
import TextField from '@/components/taskDetails/TextField.vue'
import CheckField from '@/components/taskDetails/CheckField.vue'
import RadioField from '@/components/taskDetails/RadioField.vue'
import UploadFields from '@/components/taskDetails/UploadFields.vue'
import mock from '@/mock/mock.js'

export default {
  components: {
    TextField,
    RadioField,
    CheckField,
    UploadFields
  },
  data() {
    return {
      dataList: [], //进行逻辑处理后的展示数据
      dataListString: '', //dataList 进行 stringify 用于比对禁用状态
      keepDataList: [], //未进行逻辑处理前的完整数据 为了保留答案 注意转换过程不要使用深拷贝和非变异方法
      logic: [], //逻辑数组
      show: false, //
      oldDataList: [] // 保存原始数据，方便重置使用
    }
  },
  methods: {
    //查看示例
    imgPre(sampleImg) {
      ImagePreview([sampleImg])
    },
    onClickLeft() {
      parent.postMessage('clickBack', '*')
      // Toast('返回')
    },
    /**
     * 找到目标题目后面与目标题目有逻辑关联的题目，并重置
     * @param {*} target 目标题目
     */
    resetRestValue(target) {
      // 目标题目索引
      const targetIndex = this.oldDataList.findIndex((v) => v.code === target.code)
      for (let i = targetIndex + 1; i < this.keepDataList.length; i++) {
        // 剩余题目
        const restItem = this.keepDataList[i]
        for (const logicItem of this.logic) {
          // 找到逻辑关联的题目并重置
          if (logicItem[target.code]?.logicItemCode?.includes(restItem.code)) {
            const data = JSON.parse(JSON.stringify(this.oldDataList[i]))
            // 递归清除
            this.resetRestValue(data)
            this.keepDataList[i] = data
          }
        }
      }
    },
    //改变多选框值
    changeCheckBox(item, val, index) {
      this.resetRestValue(item)
      this.dataList = this.getListByLogic(this.keepDataList, this.logic)
    },
    //改变单选框值
    changeRadioBox(item, val, index) {
      this.resetRestValue(item)
      this.dataList = this.getListByLogic(this.keepDataList, this.logic)
    },
    //提交表单
    async onSubmit(values) {
      if (this.dataList[0].isEarlyEndAnswer === true) {
        Toast('提交 -- 甄别结束')
      } else {
        Toast('提交')
      }
    },
    //获取详情数据
    getData(params) {
      console.log('子页面获取到的', params)
      let res = {}
      res.data = params
      //假数据
      // res.data = JSON.parse(JSON.stringify(mock.data1))
      // 针对新增的多图片题进行判断  赋值为itemTypeF=999
      res.data.data.itemList.forEach((el) => {
        if (el.itemType === 3) {
          if (el.itemImgs === null || el.itemImgs === undefined) {
            el.itemImgs = []
          }
          //添加默认key
          el.itemImgs.forEach((itemImgsItem, itemIndex) => {
            itemImgsItem.key = 'init' + itemIndex
          })
          let length = el.itemImgs.length
          //补全长度
          for (let index = 0; index < el.imgMinCount - length; index++) {
            el.itemImgs.push({
              relativeUrl: null,
              absoluteUrl: null,
              key: 'blank' + index
            })
          }
        }
        this.logic = res.data.data.logic || []
        this.keepDataList = res.data.data.itemList
        this.oldDataList = JSON.parse(JSON.stringify(this.keepDataList))
        this.dataList = this.getListByLogic(this.keepDataList, this.logic)
        this.dataListString = JSON.stringify(this.dataList)
      })
    },
    /** 通过逻辑计算展示的数组
     * 注意!!! 为缓存数据,在数据流转过程中,不可深拷贝数据,谨慎使用数组非变异方法和浅拷贝 如未正确返回结果请检查是否传递过程导致itemOptionCode未正确传入
     * 甄别结束>正常结束>逻辑关联
     * 入参 :
     * allList 必传 全部数据得数组
     * logicList 必传 逻辑数组
     * startCode = null 尚未开发 逻辑关联重计算时节省资源 从什么题目code未多少的位置开始重新计算 若不传则全部重新计算
     * 返回值 : 计算后的数组
     *  **/
    getListByLogic(allList, logicList) {
      //兼容传入空数组
      // if (allList.length === 0) {
      //   return allList
      // }
      const res = []
      //第一题甄别结束 且 尚未回答问题时 只展示第一题
      if (allList[0].earlyEnd === true && !allList[0].itemOptionCode) {
        res.push(allList[0])
        return res
      }
      endLogic()
      //如果第0项 isEarlyEndAnswer === true 则只展示第一题
      if (allList[0].isEarlyEndAnswer === true) {
        res.push(allList[0])
        return res
      }
      // 非甄别结束情况下 继续处理回答逻辑
      answerLogic()
      // console.log(allList)
      allList.forEach((el) => {
        //showByNormalEndlogic=false正常结束不展示  非正常结束时判断showByInitlogic===false且showByAnswerLogic!==true不展示
        if (el.showByNormalEndlogic !== false) {
          if (el.showByInitlogic !== false || (el.showByInitlogic === false && el.showByAnswerLogic === true)) {
            res.push(el)
          }
        }
      })
      return res

      // 处理回答逻辑
      function answerLogic() {
        let target = []
        //遍历逻辑数组
        logicList.forEach((logic_El) => {
          //遍历每个logic_El下的对象 实际应该只有一个键值对
          for (const key in logic_El) {
            if (Object.hasOwnProperty.call(logic_El, key)) {
              const logicElement = logic_El[key]
              /*normalEnd earlyEnd必须按照题号的顺序依次判断 但是逻辑数组不一定是按照顺序排的 所以不能直接在这里遍历*/
              //遍历logicItemCode数组
              if (logicElement.logicItemCode && logicElement.logicItemCode.length) {
                logicElement.logicItemCode.forEach((logicItemCode_EL) => {
                  // let findItem = allList.find((allList_El) => logicItemCode_EL === allList_El.code)
                  // findItem.showByInitlogic = false
                  allList.forEach((allList_El) => {
                    if (allList_El.code === logicItemCode_EL) {
                      // 初始化逻辑 被逻辑关联的题默认隐藏  showByInitlogic设置为false
                      allList_El.showByInitlogic = false
                      // 两条逻辑同时指向第三题 只要有一条命中就显示第三题
                      // 答案命中
                      let findItem = allList.find((allList_El) => key === allList_El.code)
                      if (checkAnswer(logicElement, findItem)) {
                        //把命中过得题目code存起来   logicItemCode_EL实际是等于allList_El.code的
                        target.push(logicItemCode_EL)
                        allList_El.showByAnswerLogic = true
                      } else {
                        // 本次答案未命中 且之前也未命中过
                        if (!target.includes(logicItemCode_EL)) {
                          allList_El.showByAnswerLogic = false
                        }
                      }
                    }
                  })
                })
              }
            }
          }
        })
      }

      //处理 正常结束和甄别结束逻辑
      function endLogic() {
        //是否遇到NormalEnd的开关
        let isNormalEndFlag = false
        //遍历allList
        for (let i = 0; i < allList.length; i++) {
          const el = allList[i]
          // console.log(el)
          //earlyEnd 仅针对第0项校验否是earlyEnd
          if (i === 0 && el.earlyEnd === true) {
            if (checkAnswerForEnd(el, 'earlyEnd')) {
              el.isEarlyEndAnswer = true
              break //跳出循环
            } else {
              el.isEarlyEndAnswer = false
            }
          }

          //校验normalEnd 默认开关为false 所有的showByNormalEndlogic设置为true  开关变更后showByNormalEndlogic为false
          if (isNormalEndFlag === false) {
            el.showByNormalEndlogic = true
            if (el.normalEnd === true) {
              if (checkAnswerForEnd(el, 'normalEnd')) {
                isNormalEndFlag = true
              }
            }
          } else {
            //遇到正常结束 直接将后续题目showByNormalEndlogic设置为false
            el.showByNormalEndlogic = false
          }
        }
      }

      //校验答案是否满足正常结束或者提前结束 type==='earlyEnd' || type==='normalEnd'
      function checkAnswerForEnd(el, type) {
        let flag = false
        logicList.forEach((logic_El) => {
          for (const key in logic_El) {
            if (Object.hasOwnProperty.call(logic_El, key)) {
              const logicElement = logic_El[key]
              //isFinish为earlyEnd 且key值为 传入对象的code
              if (logicElement.isFinish === type && key === el.code) {
                if (checkAnswer(logicElement, el)) {
                  flag = true
                }
              }
            }
          }
        })
        // console.log('checkAnswerForEnd', flag)
        return flag
      }

      //校验选中和未选中逻辑是否达成 如此逻辑对象 题目对象
      function checkAnswer(logicEl, itemEL) {
        // console.log(logicEl, itemEL)
        let flag = false
        let arr1 = itemEL.itemOptionCode ? itemEL.itemOptionCode.split('#').sort() : []
        let arr2 = logicEl.optionalCodeList.sort()
        // console.log(arr1, JSON.stringify(arr1))
        // console.log(arr2, JSON.stringify(arr2), logicEl.type, arr1.length)
        // console.log(JSON.stringify(arr1) !== JSON.stringify(arr2))
        if (JSON.stringify(arr1) === JSON.stringify(arr2) && logicEl.type === 'selected') {
          flag = true
        }
        // logicEl.type==='unselected'的情况  若未选中AB则表示  有选项且选中的刚好不是AB
        if (JSON.stringify(arr1) !== JSON.stringify(arr2) && logicEl.type === 'unselected' && arr1.length > 0) {
          flag = true
        }
        // console.log('checkAnswer', flag)
        return flag
      }
    },
    //计算禁用状态
    computedDisabled() {
      //此处应该校验是否每道题都有答案
      let noAnswer = false
      for (let index = 0; index < this.dataList.length; index++) {
        const element = this.dataList[index]
        //问答
        if (element.itemType === 0 || element.itemType === 5) {
          if (element.itemAnswer === null || element.itemAnswer === undefined || element.itemAnswer === '') {
            noAnswer = true
            break
          }
        }
        //单选
        if (element.itemType === 1) {
          if (
            element.itemOptionCode === null ||
            element.itemOptionCode === undefined ||
            element.itemOptionCode === ''
          ) {
            noAnswer = true
            break
          }
        }
        //多选
        if (element.itemType === 2) {
          if (
            element.itemOptionCode === null ||
            element.itemOptionCode === undefined ||
            element.itemOptionCode === ''
          ) {
            noAnswer = true
            break
          }
        }
        //图片
        // 20230206 移除itemImg字段全部使用 itemImgs
        // if (element.itemType === 3 && element.itemTypeF === 999) {
        if (element.itemType === 3) {
          let noAbsoluteUrl = false
          for (let index = 0; index < element.itemImgs.length; index++) {
            const item = element.itemImgs[index]
            if (
              index < element.imgMinCount &&
              (item.absoluteUrl === '' || item.absoluteUrl === null || item.absoluteUrl === undefined)
            ) {
              noAbsoluteUrl = true
              break
            }
          }
          if (noAbsoluteUrl) {
            noAnswer = true
            break
          }
        }
      }
      if (
        !this.checkChange() || // 未变更内容
        noAnswer //是否有未回答的题
      ) {
        return true
      } else {
        return false
      }
    },
    //校验是否有变更题目
    checkChange() {
      //剔除多图片上传中的空上传框
      let nowDataList = JSON.parse(JSON.stringify(this.dataList))
      nowDataList.forEach((el) => {
        if (el.itemType === 3) {
          el.itemImgs = el.itemImgs.filter((data) => data.relativeUrl)
        }
      })
      // 如果是没有做过的题 即使没改变内容也提示
      if (this.$store.state.taskDetailsInfo.isDone && JSON.stringify(nowDataList) === this.dataListString) {
        return false
      } else {
        return true
      }
    }
  },
  async mounted() {
    this.show = true
    //接收父页面的传参的监听方法
    window.addEventListener('message', (event) => {
      // console.log('222222222', event.data)
      if (event.data && event.data.type === 'taskDetail') {
        this.getData(event.data.data)
      }
    })
    //通知父页面已准备完成
    parent.postMessage('ready', '*')
  }
}
</script>
<style lang="less" scoped>
.flexBox {
  display: flex;
  align-items: flex-start;
}
.taskDetails {
  position: relative;
}
.btnBox {
  opacity: 0.92;
  width: 94.6%;
  height: 45px;
  position: absolute;
  bottom: 0;
  background: #fff;
  padding: 10px 2.7%;
  display: flex;
  button {
    width: 50%;
  }
  .submit {
    border-radius: 0 22px 22px 0;
  }
  .cancel {
    border-radius: 22px 0 0 22px;
    background: #e6fbff;
    border: 1px solid #76d7f5;
    color: #1a99ff;
  }
}
.posBox {
  line-height: 24px;
  min-height: 24px;
  padding: 13px 4% 13px 5.6%;
  font-size: 14px;
  .location-o {
    font-size: 16px;
  }
  .btn {
    padding: 5px;
    background: #fdeceb;
    color: #ff4d4f;
    font-size: 12px;
    margin-left: 10px;
    border: 1px solid #ffbab7;
    border-radius: 2px;
    width: 80px;
    height: 20px;
    line-height: 20px;
  }
}
.cardBox {
  background: #fff;
  margin-bottom: 10px;
  padding: 15px 5.3% 20px 5.3%;
  color: #333333;
}
.required {
  color: #ff4d4f;
  margin-right: 5px;
}
.tips {
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  color: #52c41a;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 13px;
  display: inline-block;
}
.tipsnotstart {
  background: #ffffff;
  border: 1px solid #9da4b1;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  color: #9da4b1;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 13px;
  display: inline-block;
}
.tipsend {
  background: #ffffff;
  border: 1px solid #ff6633;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  color: #ff6633;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 13px;
  display: inline-block;
}
.tipsgoon {
  background: #ffffff;
  border: 1px solid #4badff;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  color: #4badff;
  font-weight: 400;
  margin-left: 10px;
  padding: 3px 13px;
  display: inline-block;
}
.tittleBox {
  color: #333333;
  font-weight: 900;
}
.describe {
  color: #666666;
  text-align: justify;
}
.taskTittle {
  color: #333333;
  text-align: justify;
}
.disabledSub {
  background: #ccc;
  border-color: #ccc;
}
.taskDetails .van-action-sheet__header {
  font-size: 18px;
  color: #333333;
  text-align: center;
  line-height: 60px;
  font-weight: 900;
  background: #fafafa;
}
.example {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  .arrow {
    position: relative;
    top: 2px;
    margin-left: 13px;
  }
}
</style>
